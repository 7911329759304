
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/inspection";

@Options({})
export default class extends Vue {
  private refreshing2 = false;
  private loading2 = false;
  private finished2 = false;
  private list2: any[] = [];

  protected onLoad2() {
    setTimeout(() => {
      if (this.refreshing2) {
        this.list2 = [];
        this.refreshing2 = false;
      }

      api
        .pageWay({
          projectId: this.$store.state.project.projectinfo.projectId,
          pageNum: parseInt(this.list2.length / 10 + "") + 1,
          pageSize: 10,
        })
        .then((res: any) => {
          this.list2 = [...this.list2, ...res.data.records];
          this.loading2 = false;

          if (this.list2.length >= res.data.total) {
            this.finished2 = true;
          }
        });
    }, 1000);
  }

  getPercent2(countFinish: any, everydayTimes: any): number {
    try {
      everydayTimes = parseInt(everydayTimes);
    } catch (e) {
      return 0;
    }
    if (everydayTimes === 0) {
      return 0;
    }
    if (countFinish <= everydayTimes) {
      return (countFinish / everydayTimes) * 100;
    } else {
      return 100;
    }
  }

  onRefresh2() {
    this.finished2 = false;
    this.loading2 = true;
    this.onLoad2();
  }

  protected startInspect(li: any) {
    this.$router.push({ path: "/app/inspectioninfo", query: { wayId: li.id } });
  }
}
