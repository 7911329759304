
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/inspection";

@Options({})
export default class extends Vue {
  private refreshing1 = false;
  private loading1 = false;
  private finished1 = false;
  private list1: any[] = [];
  private images: any[] = ["point1.png", "point2.png", "point3.png"];
  private list1Images: any[] = [];

  protected onLoad1() {
    setTimeout(() => {
      if (this.refreshing1) {
        this.list1 = [];
        this.refreshing1 = false;
      }

      api
        .listPoint({
          projectId: this.$store.state.project.projectinfo.projectId,
          pageNum: parseInt(this.list1.length / 10 + "") + 1,
          pageSize: 10,
        })
        .then((res: any) => {
          //保持和原型图的静态图片次序一致
          let num = parseInt(res.data.total / 3 + 1 + "");
          for (let i = 0; i < num; i++) {
            this.images.map((item: any) => {
              this.list1Images.push(item);
            });
          }
          this.list1 = [...this.list1, ...res.data.records];
          this.loading1 = false;

          if (this.list1.length >= res.data.total) {
            this.finished1 = true;
          }
        });
    }, 1000);
  }

  getPercent1(countFinish: any, everydayTimes: any): number {
    try {
      everydayTimes = parseInt(everydayTimes);
    } catch (e) {
      return 0;
    }
    if (everydayTimes === 0) {
      return 0;
    }
    if (countFinish <= everydayTimes) {
      return (countFinish / everydayTimes) * 100;
    } else {
      return 100;
    }
  }

  onRefresh1() {
    this.finished1 = false;
    this.loading1 = true;
    this.onLoad1();
  }
}
